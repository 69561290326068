import { useEffect, useState } from 'react'

import { useAppSelector } from '@src/app/hooks'

export type FeatureNames =
  | 'short_videos'
  | 'live_events'
  | 'digital_showrooms'
  | 'one_to_one'
  | 'live_events_auto_response'
  | 'ig_importer_business'
  | 'ava'

export type FeatureEnablement = {
  [key in FeatureNames]?: boolean
}

export const useFeatureEnablement = (businessId: string): FeatureEnablement => {
  const [featureEnablement, setFeatureEnablement] = useState({})
  const businessData = useAppSelector(
    (state) => state.business.businesses[businessId]
  )

  useEffect(() => {
    // change feature checks to feature enablement
    const fe = getFeatureEnablement(businessData)
    setFeatureEnablement(fe)
  }, [businessData])

  return featureEnablement
}

export const getFeatureEnablement = (businessData: any): FeatureEnablement => {
  if (businessData?.subscription?.feature_checks === undefined) return {}
  const fe: FeatureEnablement = {}
  businessData?.subscription?.feature_checks.forEach((feature) => {
    fe[feature.feature_name] = feature.enabled
  })

  return fe
}
